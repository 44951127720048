import { Link } from 'gatsby'
import * as React from 'react'

// styles
const pageStyles = {
  padding: '96px'
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320
}

const paragraphStyles = {
  marginBottom: 48
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>Sorry, we couldn’t find what you were looking for.</p>
      <p style={paragraphStyles}><Link to="/">Return to home</Link>.</p>
    </main>
  )
}

export default NotFoundPage
